import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-default-rest-spread",
  "date": "2015-09-10",
  "title": "ES6 DEFAULT, REST & SPREAD",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Many programming languages support the concept of a default argument for a function parameter so that the caller does not always need to specify the argument value. Unfortunately, ES5 does not have a default argument support in its syntax. ES6 functions have come a long way by taking aboard the years of complaints and improving parameter handling that is less error-prone and more powerful."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`default`}</h2>
    <p>{`Functions in JavaScript allow any number of parameters to be passed regardless of the number of declared parameters in the function definition. JavaScript programmers employ various different run-time tricks to achieve the effect of an argument with a default value. The common pattern for parameter handling is as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function sum(number1, number2) {
    // set default to 0 if number2 not passed
    // (or passed as undefined)
    number2 = number2 || 0;
    return number1 + increment;
}

console.log(sum(2, 3)); // 5
console.log(sum(2));    // 2
`}</code></pre>
    <p>{`The logical `}<inlineCode parentName="p">{`OR`}</inlineCode>{` operator (||) always returns the second operand when the first is `}<inlineCode parentName="p">{`falsy`}</inlineCode>{`.`}</p>
    <p>{`ES6 gives us a way to set default function parameters. Any parameters with a default value are considered to be optional.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function inc(number1, number2 = 0) {
  return number1 + number2;
}

console.log(inc(2, 3)); // 5
console.log(inc(2));    // 2
`}</code></pre>
    <p>{`You can also set default values to parameters that appear `}<inlineCode parentName="p">{`before arguments`}</inlineCode>{` without default values:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function sum(a, b = 2, c) {
  return a + b + c;
}

console.log(sum(1, 5, 10));         // 16 -> b === 5
console.log(sum(1, undefined, 10)); // 13 -> b as default
`}</code></pre>
    <p>{`You can even execute a function to set default parameter. So it’s not restricted to `}<inlineCode parentName="p">{`primitive values`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function getDefaultIncrement() {
  return 1;
}

function inc(number, increment = getDefaultIncrement()) {
  return number + increment;
}

console.log(inc(2, 2)); // 4
console.log(inc(2));    // 3
`}</code></pre>
    <h2>{`rest`}</h2>
    <p>{`In ES5, when you want to handle all the arguments passed into it, we would have to use the `}<strong parentName="p">{`arguments`}</strong>{` object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  function sum() {
   var numbers = Array.prototype.slice.call(arguments),
       result = 0;
   numbers.forEach(function (number) {
       result += number;
   });
   return result;
  }
  console.log(sum(1));             // 1
  console.log(sum(1, 2, 3, 4, 5)); // 15
`}</code></pre>
    <p>{`Here we have to iterate the arguments array and sum up the numbers.`}</p>
    <Message type="warn" title="arguments object" content="The **arguments** object is not quite an array - it's an array like object. We have to convert it to an `Array` using the **slice** function." mdxType="Message" />
    <p>{`ES6 introduces `}<strong parentName="p">{`rest`}</strong>{` parameters to overcomes these difficulties.`}</p>
    <p>{`Indicated by three dots `}<strong parentName="p">{`...`}</strong>{` similar to the `}<inlineCode parentName="p">{`splat`}</inlineCode>{` operator in `}<inlineCode parentName="p">{`Ruby`}</inlineCode>{`.`}</p>
    <p>{`Named parameters become an array containing the rest of the parameters.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function sum(…numbers) {
  var result = 0;
  numbers.forEach(function (number) {
    result += number;
  });
 return result;
}

console.log(sum(1)); // 1
console.log(sum(1, 2, 3, 4, 5)); // 15
`}</code></pre>
    <Message type="warn" title="" content="No other named arguments can follow in the function declaration." mdxType="Message" />
    <p>{`The folowing will throw an error:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function sum(…numbers, last) { // causes a syntax error
`}</code></pre>
    <h2>{`spread`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`spread`}</inlineCode>{` is closely related to rest parameters, because of … (three dots) notation. It allows to split an array to single arguments which are passed to the function as separate arguments.`}</p>
    <p>{`Let’s define our sum function and pass spread to it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function sum(a, b, c) {
  return a + b + c;
}

var args = [1, 2, 3];
console.log(sum(…args)); // 6
`}</code></pre>
    <p>{`So instead using an `}<inlineCode parentName="p">{`apply`}</inlineCode>{` function, we can just type `}<strong parentName="p">{`…args`}</strong>{` and pass all array argument separately.`}</p>
    <p>{`We can also mix standard arguments with spread operator:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function sum(a, b, c) {
  return a + b + c;
}

var args = [1, 2];
console.log(sum(…args, 3)); // 6
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      